import React, { Component } from 'react';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import NoPostsFeed from '../../../common/containers/no-posts-feed';
import PostListProGallery from '../../../common/components/post-list-pro-gallery';
import {
  SECTION_POST_LIST,
  IS_COVER_IMAGE_ENABLED_PATH,
  POST_LIST_WIDGET_DEFAULTS,
  POST_LIST_SETTINGS_PARAMS,
  POST_LIST_LAYOUT_PARAMS,
  isLayoutSlider,
  isLayoutPGSideBySide,
  getLayoutName,
  LAYOUT_PG_GRID,
} from '@wix/communities-blog-client-common';
import { getFeedPosts } from '../../../common/selectors/post-selectors';
import { getIsEntityLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import { getLayoutType } from '../../../common/selectors/layout-selectors';
import { Pagination } from '../../../common/containers/pagination/pagination';
import { getPostCount } from '../../selectors/post-count-selector';
import { getPostListWidgetPageSize } from '../../selectors/post-list-widget-page-size';

import styles from './post-list.scss';
import withDeviceType from '../../../common/hoc/with-device-type';

const { sliderArrowsPosition } = POST_LIST_LAYOUT_PARAMS;

class PostList extends Component {
  state = { page: 1 };

  componentDidUpdate({ pageSize, featuredOnly, isPaginationEnabled }) {
    if (
      this.props.pageSize !== pageSize ||
      this.props.featuredOnly !== featuredOnly ||
      this.props.isPaginationEnabled !== isPaginationEnabled
    ) {
      this.props.fetchPostListPosts(this.state.page, this.props.pageSize);
    }
  }

  render() {
    const {
      posts,
      postCount,
      isLoading,
      componentId,
      layoutType,
      layoutName,
      layoutOptions,
      hideCoverImage,
      pageSize,
      isPaginationEnabled,
    } = this.props;
    const hasPosts = Boolean(posts.length);

    if (!isLoading && !hasPosts) {
      return <NoPostsFeed />;
    }

    return (
      <div>
        <PostListProGallery
          className={layoutOptions.arrowsPosition === sliderArrowsPosition.values.ON_GALLERY ? 'arrows-on-gallery' : ''}
          layoutName={layoutName}
          layoutType={layoutType}
          entityCount={POST_LIST_WIDGET_DEFAULTS.entityCount}
          layoutDefaults={POST_LIST_WIDGET_DEFAULTS}
          domId={componentId}
          allPosts={posts}
          currentPagePosts={posts}
          isLoading={isLoading}
          showCreatePostAction={false}
          section={SECTION_POST_LIST}
          hideThreeDots={true}
          layoutOptions={layoutOptions}
          hideCoverImage={hideCoverImage}
        />
        {isPaginationEnabled && (
          <Pagination
            useDescriptionColor={true}
            containerClassName={styles.paginationContainer}
            page={this.state.page}
            onChange={({ page }) => {
              this.setState({ page });
              this.props.fetchPostListPosts(page, pageSize);
            }}
            pageSize={pageSize}
            section={SECTION_POST_LIST}
            entityCount={postCount}
            createPageUrl={() => {}}
            activeClass={'blog-post-list-pagination-active'}
          />
        )}
      </div>
    );
  }
}

const mapRuntimeToProps = (state, { isMobile }, actions, host) => {
  const {
    sliderShowArrows,
    sliderArrowsSize,
    sliderAutoSlide,
    sliderPauseTime,
    sliderArrowsPosition,
    sliderArrowsColor,
    sliderLoop,
  } = POST_LIST_LAYOUT_PARAMS;
  let layoutType = getLayoutType(state, SECTION_POST_LIST) || POST_LIST_WIDGET_DEFAULTS.layoutType;

  if (isLayoutPGSideBySide(layoutType) && isMobile) {
    layoutType = LAYOUT_PG_GRID;
  }

  const layoutName = getLayoutName(layoutType) || POST_LIST_WIDGET_DEFAULTS.layoutName;
  const paginationType = getAppSettingsValue({
    state,
    key: POST_LIST_SETTINGS_PARAMS.paginationType.appSettingsPath,
    fallback: POST_LIST_SETTINGS_PARAMS.paginationType.defaultValue,
  });
  return {
    isPaginationEnabled:
      !isLayoutSlider(layoutType) &&
      String(paginationType) !== String(POST_LIST_SETTINGS_PARAMS.paginationType.values.NUMBER_OF_POSTS),
    postCount: getPostCount(state),
    posts: getFeedPosts(state),
    layoutType,
    layoutName,
    isLoading: getIsEntityLoading(state, 'postListPosts'),
    componentId: host.id,
    pageSize: getPostListWidgetPageSize(state),
    hideCoverImage: !getAppSettingsValue({
      state,
      key: IS_COVER_IMAGE_ENABLED_PATH,
      fallback: true,
    }),
    layoutOptions: {
      showArrows: getAppSettingsValue({
        state,
        key: sliderShowArrows.appSettingsPath,
        fallback: sliderShowArrows.defaultValue,
      }),
      arrowsSize: getAppSettingsValue({
        state,
        key: sliderArrowsSize.appSettingsPath,
        fallback: sliderArrowsSize.defaultValue,
      }),
      autoSlide: getAppSettingsValue({
        state,
        key: sliderAutoSlide.appSettingsPath,
        fallback: sliderAutoSlide.defaultValue,
      }),
      pauseTime: getAppSettingsValue({
        state,
        key: sliderPauseTime.appSettingsPath,
        fallback: sliderPauseTime.defaultValue,
      }),
      arrowsPosition: getAppSettingsValue({
        state,
        key: sliderArrowsPosition.appSettingsPath,
        fallback: sliderArrowsPosition.defaultValue,
      }),
      arrowsColor: getAppSettingsValue({
        state,
        key: sliderArrowsColor.appSettingsPath,
        fallback: sliderArrowsColor.defaultValue,
      }),
      loop: getAppSettingsValue({ state, key: sliderLoop.appSettingsPath, fallback: sliderLoop.defaultValue }),
    },
    featuredOnly: getAppSettingsValue({ state, key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath }),
    fetchPostListPosts: actions.fetchPostListPostsPromisified,
  };
};

export default flowRight(withDeviceType, connect(mapRuntimeToProps))(PostList);
